<template>
  <Container :style="{ padding: 0 }">
    <div class="pt-14 pb-10 px-10 mb-10 relative">
      <strong class="font-bold block mb-1">LOT Inside Thumbnail Image</strong>
      <div class="flex items-end mb-10">
        <div
            class="
          border
          flex
          items-center
          justify-center
          cursor-pointer
          drag-container
          relative

        "
            style="width: 427px; height: 215px;"
            @click="handleClickImageInput"
        >
          <input
              type="file"
              multiple
              name="productImageList"
              accept=".pdf,.jpg,.jpeg,.png"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              ref="imageFilesInputEl"
              @change="handleChangeImageInput"
          />
          <div class="file-image"
               :style="{
                backgroundImage: `url(${inputs.thumbnailImage})`,
              }"
          ></div>
          <div ref="bannerText2" class="hidden justify-center items-center hover-file-text">
            <IconBase
                :width="48"
                :height="48"
                color="#ffffff"
                viewBox="0 0 48 48"
            >
              <PictureIcon></PictureIcon>
            </IconBase>
          </div>
        </div>
        <p class="text-gray-400 text-sm pl-4">800 * 400 이상 사이즈의 JPG 파일로 업로드</p>
      </div>
      <label for="title" class="font-bold block mb-1">LOT Inside Title</label>
      <input type="text" id="title" v-model="inputs.title" class="border border-black flex-1 h-10 py-3 px-4 text-xs w-full" placeholder="제목을 입력해주세요.">
      <strong class="font-bold block mt-4 mb-1">Content</strong>
      <div ref="toastEditor" id="toastEditor"></div>
      <div class="flex items-center justify-center">
        <button
            class="
              w-60
              h-16
              border border-black
              shadow
              flex
              items-center
              justify-center
              mt-10
              mr-10
              admin-button
            "
            @click="gotoDetail"
        >
          <IconBase>
            <UndoIcon></UndoIcon>
          </IconBase>
          <span class="ml-2">취소</span>
        </button>
        <template v-if="lotInsideIdParam">
          <button
              class="
              w-60
              h-16
              border border-black
              shadow
              flex
              items-center
              justify-center
              mt-10
              admin-button
            "
              type="submit"
              @click="handleEdit(lotInsideIdParam)"
          >
            <IconBase>
              <CheckCircleIcon></CheckCircleIcon>
            </IconBase>
            <span class="ml-2">수정</span>
          </button>
        </template>
        <button
            v-else
            class="
              w-60
              h-16
              border border-black
              shadow
              flex
              items-center
              justify-center
              mt-10
              admin-button
            "
            type="submit"
            @click="handleRegister(lotInsideDetail)"
        >
          <IconBase>
            <CheckCircleIcon></CheckCircleIcon>
          </IconBase>
          <span class="ml-2">확인</span>
        </button>

      </div>
    </div>
  </Container>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, onUnmounted } from 'vue'
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import UndoIcon from '@/components/icons/UndoIcon.vue';
import '@toast-ui/editor/dist/toastui-editor.css';
import Editor from '@toast-ui/editor';
import PictureIcon from '@/views/layouts/AppLayout/icons/PictureIcon.vue';
import axios from 'axios';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'LotInsideEdit',
  components: {
    Container,
    IconBase,
    CheckCircleIcon,
    UndoIcon,
    PictureIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    let xeditor;
    const lotInsideDetail = ref<any>(null);
    const lotInsideIdParam = ref<number>();
    const route = useRoute();
    const imageFilesInputEl = ref<HTMLInputElement | null>(null);
    const inputs = ref({
      title: '',
      contents: '',
      thumbnailImage: '' || '/images/default-img-blue.png'
    })
    const toastEditor = ref(null);

    const gotoDetail = () => {
      router.go(-1);
    }
    const handleChangeImageInput = async (e) => {
      if (e.target.files) {
        uploadImageFiles(e.target.files);
      }
    };
    const handleClickImageInput = async (e) => {
      if (imageFilesInputEl.value) {
        imageFilesInputEl.value.click();
      }
    };
    const uploadImageFiles = async (files: FileList) => {
      try {
        let uploadedImageUrl = ''
          const formData = new FormData();
          formData.append('file', files[0]);

          const { data } = await axios.post(
              process.env.VUE_APP_API_HOST + '/admin-api/file/upload/lotinside',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
          );

          uploadedImageUrl = data
          inputs.value.thumbnailImage = uploadedImageUrl;
      } catch (error) {
        console.error(error);
      }
    };
    const handleRegister = async () => {
      const body = {
        ...inputs.value,
        contents: xeditor.getHTML()
      }
      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInsideCreate({
          body
        });
        alert(data.message)
        router.push({
          path: '/app/lotInside/list'
        })
      } catch (error) {
        console.error(error);
        alert(error)
      }
    }
    const handleEdit = async (lotInsideId) => {
      const body = {
        ...inputs.value,
        contents: xeditor.getHTML()
      }
      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInsideUpdate({
          lotInsideId,
          body
        });
        alert(data.message)
        router.push({
          path: '/app/lotInside/list'
        })
      } catch (error) {
        console.error(error);
        alert(error)
      }
    }

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    onMounted( async () => {
      lotInsideIdParam.value = route.params.lotInsideId
          ? parseInt(route.params.lotInsideId as string)
          : undefined;
      console.log(route.params.lotInsideId)
      if (lotInsideIdParam.value) {
        await fetchLotInside(lotInsideIdParam.value);
      }

      xeditor = new Editor({
        el: toastEditor.value || new HTMLElement(),
        height: '500px',
        previewStyle: 'vertical',
        initialEditType: 'wysiwyg',
        initialValue: inputs.value.contents,
        hooks: {
          addImageBlobHook: async (blob, callback) => {
            const formData = new FormData();
            formData.append('file', blob);
            const { data } = await axios.post(
                process.env.VUE_APP_API_HOST + '/admin-api/file/upload/lotinside',
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }
            );
            callback(data, "alt text");
            return false
          },
        },
      });
/*

      xeditor.addHook('change', () => {
        console.log('change')
        console.log(xeditor.getHTML())
      })
*/

    })

    const fetchLotInside = async (lotInsideId) => {
      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInside({
          lotInsideId,
        });
        inputs.value = (data as any).data;
        // inputs.value.contents = (data as any).data.contents?.replace(/\n/g, '<br/>')
        console.log(data)
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    return {
      inputs,
      lotInsideIdParam,
      lotInsideDetail,
      handleClickImageInput,
      handleChangeImageInput,
      imageFilesInputEl,
      gotoDetail,
      handleRegister,
      handleEdit,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
      toastEditor,
    };
  },
});
</script>

<style scoped lang="scss">

.member-list__link {
  position: absolute;
  bottom: -60px;
  left: 0;
}
@import "../../../assets/style/label";
.file-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.hover-file-text {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.drag-container:hover {
  .hover-file-text {
    display: flex;
  }
}

#toastEditor ::v-deep .toastui-editor-contents {
  font-size: 15px;
  *:not(table) {
    line-height: 1.5;
  }
  img {
    margin: 0;
  }
}
</style>
