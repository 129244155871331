
import { computed, defineComponent, onMounted, ref, onUnmounted } from 'vue'
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import UndoIcon from '@/components/icons/UndoIcon.vue';
import '@toast-ui/editor/dist/toastui-editor.css';
import Editor from '@toast-ui/editor';
import PictureIcon from '@/views/layouts/AppLayout/icons/PictureIcon.vue';
import axios from 'axios';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'LotInsideEdit',
  components: {
    Container,
    IconBase,
    CheckCircleIcon,
    UndoIcon,
    PictureIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    let xeditor;
    const lotInsideDetail = ref<any>(null);
    const lotInsideIdParam = ref<number>();
    const route = useRoute();
    const imageFilesInputEl = ref<HTMLInputElement | null>(null);
    const inputs = ref({
      title: '',
      contents: '',
      thumbnailImage: '' || '/images/default-img-blue.png'
    })
    const toastEditor = ref(null);

    const gotoDetail = () => {
      router.go(-1);
    }
    const handleChangeImageInput = async (e) => {
      if (e.target.files) {
        uploadImageFiles(e.target.files);
      }
    };
    const handleClickImageInput = async (e) => {
      if (imageFilesInputEl.value) {
        imageFilesInputEl.value.click();
      }
    };
    const uploadImageFiles = async (files: FileList) => {
      try {
        let uploadedImageUrl = ''
          const formData = new FormData();
          formData.append('file', files[0]);

          const { data } = await axios.post(
              process.env.VUE_APP_API_HOST + '/admin-api/file/upload/lotinside',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
          );

          uploadedImageUrl = data
          inputs.value.thumbnailImage = uploadedImageUrl;
      } catch (error) {
        console.error(error);
      }
    };
    const handleRegister = async () => {
      const body = {
        ...inputs.value,
        contents: xeditor.getHTML()
      }
      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInsideCreate({
          body
        });
        alert(data.message)
        router.push({
          path: '/app/lotInside/list'
        })
      } catch (error) {
        console.error(error);
        alert(error)
      }
    }
    const handleEdit = async (lotInsideId) => {
      const body = {
        ...inputs.value,
        contents: xeditor.getHTML()
      }
      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInsideUpdate({
          lotInsideId,
          body
        });
        alert(data.message)
        router.push({
          path: '/app/lotInside/list'
        })
      } catch (error) {
        console.error(error);
        alert(error)
      }
    }

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    onMounted( async () => {
      lotInsideIdParam.value = route.params.lotInsideId
          ? parseInt(route.params.lotInsideId as string)
          : undefined;
      console.log(route.params.lotInsideId)
      if (lotInsideIdParam.value) {
        await fetchLotInside(lotInsideIdParam.value);
      }

      xeditor = new Editor({
        el: toastEditor.value || new HTMLElement(),
        height: '500px',
        previewStyle: 'vertical',
        initialEditType: 'wysiwyg',
        initialValue: inputs.value.contents,
        hooks: {
          addImageBlobHook: async (blob, callback) => {
            const formData = new FormData();
            formData.append('file', blob);
            const { data } = await axios.post(
                process.env.VUE_APP_API_HOST + '/admin-api/file/upload/lotinside',
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }
            );
            callback(data, "alt text");
            return false
          },
        },
      });
/*

      xeditor.addHook('change', () => {
        console.log('change')
        console.log(xeditor.getHTML())
      })
*/

    })

    const fetchLotInside = async (lotInsideId) => {
      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInside({
          lotInsideId,
        });
        inputs.value = (data as any).data;
        // inputs.value.contents = (data as any).data.contents?.replace(/\n/g, '<br/>')
        console.log(data)
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    return {
      inputs,
      lotInsideIdParam,
      lotInsideDetail,
      handleClickImageInput,
      handleChangeImageInput,
      imageFilesInputEl,
      gotoDetail,
      handleRegister,
      handleEdit,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
      toastEditor,
    };
  },
});
